.container {
  @apply bg-gray-900 border border-yellow-600 rounded-md flex flex-row;
}

.metricContainer {
  @apply flex flex-col p-4 justify-center justify-items-center;
}

.value {
  @apply text-white text-2xl text-center;
}

.label {
  @apply text-white text-center;
}
