.container {
  @apply w-full relative text-center;
}

button.dealButton {
  @apply bg-yellow-400 font-bold py-6 px-12 text-black text-5xl tracking-wide rounded-2xl font-alfa mr-auto;
}

button.resetButton {
  @apply absolute font-bold py-2 px-8 rounded-lg right-12 bottom-12 bg-transparent border-4 border-yellow-400 text-yellow-400 text-lg;
}
