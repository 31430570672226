.body {
  @apply bg-transparent absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-between;
}

.actionsContainer {
  @apply h-48 flex justify-center justify-items-center align-top;
}

.header {
  @apply flex justify-center justify-items-center pt-6;
}
